import { RouteRecordRaw } from 'vue-router';

const requiresAuth = true;

export const devicesRoutes: RouteRecordRaw[] = [
  {
    name: 'Devices',
    path: 'systems/:system_id/devices',
    component: () => import('src/pages/devices/ViewDevices'),
    meta: {
      requiresAuth,
    },
  },
  {
    name: 'AddDevices',
    path: 'systems/:system_id/devices/add',
    component: () => import('src/pages/devices/AddDevices'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'EditDevice',
    path: 'systems/:system_id/devices/:device_id/edit',
    component: () => import('src/pages/devices/EditDevice'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'ManageDevices',
    path: 'settings/systems/:system_id/manage-devices',
    component: () => import('src/pages/devices/AddDevices'),
    meta: {
      requiresAuth: true,
      showBackBtn: true,
    },
  },
  {
    name: 'BatteryIntegrations',
    path: 'systems/:system_id/integrations',
    component: () => import('src/pages/Integrations/Integrations.vue'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'SetupIntegration',
    path: 'systems/:system_id/integrations/:provider_name',
    component: () => import('src/pages/Integrations/SetupIntegration.vue'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'IntegrationError',
    path: 'systems/:system_id/integrations/:provider_name/error',
    component: () => import('src/pages/Integrations/IntegrationError.vue'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
  {
    name: 'IntegrationSuccess',
    path: 'systems/:system_id/integrations/:provider_name/success',
    component: () => import('src/pages/Integrations/IntegrationSuccess.vue'),
    meta: {
      requiresAuth,
      showBackBtn: true,
    },
  },
];
