import { createApp } from 'vue';
import { Quasar, Notify, LocalStorage, LoadingBar } from 'quasar';
import BuildingBlocks from '@luminsmart/building-blocks';

// Import icon libraries
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';

// Import Quasar css
import 'quasar/src/css/index.sass';

// Import our css
import './css/dark.scss';
import './css/light.scss';
import './css/overrides.scss';
import './css/positioning.scss';
import './css/typography.scss';

import '@luminsmart/building-blocks/dist/building-blocks.css';
import './css/app.scss';

// Assumes your root component is App.vue
// and placed in same folder as main.js
import App from './App.vue';
import pinia from './stores';
import { setupAuthConfig } from './boot/auth';
import { setupAxios } from './boot/axios';
import { instance } from './boot/i18n';
import setupRouter from './router';
// import { loadComponents } from './boot/iconSet.ts';
import { useEnvironments } from './composables/api/useEnvironments.ts';
import en from './locales/en.json';

// Sentry
import * as Sentry from '@sentry/vue';
const myApp = createApp(App);

// Set up monitoring (before other setups to catch errors)
const { getEnvironment } = useEnvironments();
Sentry.init({
  app: myApp,
  dsn: getEnvironment.value.sentry.VITE_SENTRY_DSN,
  environment: getEnvironment.value.id,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  beforeSend(event) {
    if (event.user) {
      event.user.ip_address = undefined;
    }
    return event;
  },
  tracesSampleRate: 0.5,
  tracePropagationTargets: [/^https:\/\/api\.luminsmart\.io/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
});

// Setup Quasar before Axios or Router
myApp.use(Quasar, {
  plugins: { Notify, LocalStorage, LoadingBar },
  config: {
    capacitor: {
      backButton: false,
      backButtonExit: ['/home'],
      iosStatusBarPadding: false,
    },
    loadingBar: { color: 'primary' },
  },
});

// Axios should be initialized early
setupAxios(myApp);

// State management
myApp.use(pinia);

// Internationalization
myApp.use(instance);

// Router (should come before auth setup to prevent navigation issues)
myApp.use(setupRouter());

// Auth and API setup
const authConfig = setupAuthConfig();
myApp.use(BuildingBlocks, {
  api: { baseUrl: getEnvironment.value.api.VITE_API_BASE_URL },
  auth: { auth0: authConfig },
  theme: getEnvironment.value.theme.name || 'default',
  locales: { en },
});

// Finally, mount the app
myApp.mount('#app');
