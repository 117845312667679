import { useSystemsStore } from 'src/stores/systems';
import { createRouter, createWebHistory } from 'vue-router';
import { useAppLoadingStatus } from '../composables/useAppLoadingStatus';
import routes from './routes';
import { useAuth } from 'src/composables/useAuth';
import { useOrganizations } from '@luminsmart/building-blocks';
import { until } from '@vueuse/core';

export default function setupRouter() {
  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,
    history: createWebHistory(import.meta.env.VITE_VUE_ROUTER_BASE),
  });

  Router.beforeEach(async (to) => {
    const auth = useAuth();
    const systemsStore = useSystemsStore();
    const organizationStore = useOrganizations();
    const appLoading = useAppLoadingStatus();

    // Wait for auth to finish loading before proceeding
    if (auth?.isLoading?.value !== false) {
      appLoading.setLoading(true);
      await until(auth.isLoading).toBe(false, { timeout: 3000 });
      appLoading.setLoading(false);
    }

    // Redirect to AuthPage if authentication is required but user is not logged in
    if (
      (to.meta.requiresAuth || to.name === 'Root') &&
      !auth.isAuthenticated?.value
    ) {
      return { name: 'AuthPage' };
    }

    // If user is already logged in and navigates to the AuthPage, redirect them appropriately
    if (
      (to.name === 'AuthPage' || to.name === 'Root') &&
      auth.isAuthenticated?.value
    ) {
      appLoading.setLoading(true);

      // Ensure systems are loaded before making a navigation decision
      if (
        !systemsStore.getSelectedSystemId?.length &&
        (systemsStore.systems.length === 0 ||
          organizationStore.systems.value.length === 0)
      ) {
        try {
          await systemsStore.fetchSystems();
        } catch (error) {
          console.error('Error fetching systems:', error);
        }
      }

      appLoading.setLoading(false);

      if (!systemsStore.getSelectedSystemId?.length) {
        return { name: 'StartSetup' };
      } else {
        return {
          name: 'Home',
          params: { system_id: systemsStore.getSelectedSystemId },
        };
      }
    }
  });

  return Router;
}
