import { defineStore } from 'pinia';
import { useApi } from 'src/composables/api/useApi';
import { hashCode, semverCompare } from './util';
import { version } from '../../package.json';
import { Platform } from 'quasar';
import { LocalStorage } from 'quasar';
import { useAnalyticsStore } from './useAnalyticsStore';
import { useAppBanners } from 'src/composables/useAppBanners';
import {
  Article,
  Dialog,
  SupportHours,
  Banner,
  StorageStatuses,
} from 'types/Notification';
import { ref } from 'vue';
import { computed } from 'vue';
import { useEnvironments } from 'src/composables/api/useEnvironments';
import { FeatureFlag } from 'src/types/FeatureFlag';
import { System } from 'src/types/System';
import { HubInfo } from 'src/types/HubInfo';

export const useSupportStore = defineStore('supportStore', () => {
  const analyticsStore = useAnalyticsStore();
  const { getEnvironment } = useEnvironments();
  const BASE_URL = getEnvironment.value.api.VITE_SUPPORT_API_URL;

  const { request } = useApi();

  const articles = ref<Article[]>([]);
  const banners = ref<Banner[]>([]);
  const dialogs = ref<Dialog[]>([]);
  const flags = ref<FeatureFlag[]>([]);
  const supportHours = ref<SupportHours>({ start: 9, end: 18 }); //DEFAULT HOURS in case something goes wrong with the support API);
  function $reset() {
    articles.value = [];
    banners.value = [];
    dialogs.value = [];
    flags.value = [];
  }

  const getArticles = computed(() => articles.value);
  const getSupportHours = computed(() => supportHours.value);
  const getDialogs = computed(() => filterStatuses(dialogs.value, 'dialogs'));

  function fetchArticles() {
    const config = request({
      url: '/articles',
      baseURL: BASE_URL,
      method: 'GET',
      requiresAuth: false,
      instanceId: 'cloud',
      options: { timeout: 15000 },
    });
    config.execute().then((response) => {
      if (response) {
        articles.value = response.data.value;
      }
    });

    return config;
  }

  function fetchSupportHours() {
    const config = request({
      url: '/support-hours',
      baseURL: BASE_URL,
      method: 'GET',
      requiresAuth: false,
      instanceId: 'cloud',
    });
    config.execute().then((response) => {
      if (response) {
        supportHours.value = response.data.value;
      }
    });

    return config;
  }

  function fetchStatuses() {
    const config = request({
      url: '/statuses',
      baseURL: BASE_URL,
      method: 'GET',
      requiresAuth: false,
      instanceId: 'cloud',
      retries: 4,
    });
    config.execute().then((response) => {
      if (response) {
        // We want to create a unique hash based on the title description and url so we can know if the user has clicked the close button
        const { banners: bnnrs, dialogs: dlgs } = createUniqueIds(
          response.data.value,
        );
        const filteredBanners = filterStatuses(bnnrs, 'banners');
        const appBanners = useAppBanners();
        // Filter out any banners before we
        filteredBanners.forEach((banner) => {
          banner.isSupportStatus = true;
          appBanners.createBanner(banner as Banner);
        });
        dialogs.value = dlgs;
      }
    });

    return config;
  }

  async function createTicket(data: Record<string, unknown>) {
    analyticsStore.trackEvent('create-ticket', {});
    const config = request({
      url: '/tickets',
      baseURL: BASE_URL,
      method: 'POST',
      requiresAuth: false,
      data,
      instanceId: 'cloud',
      retries: 4,
    });
    return await config.execute().then((response) => {
      if (response) {
        return true;
      }
      return false;
    });
  }

  // save the ids of dialogs and banners so we can show or hide them
  // TODO: this isn't being used by the banners anymore... only dialogs.
  function saveViewedStatusId(
    id: number | string,
    type: 'banners' | 'dialogs',
  ) {
    const obj = type === 'banners' ? banners.value : dialogs.value;
    const viewedStatuses: StorageStatuses = LocalStorage.getItem(
      'viewed-status-ids',
    ) || { banners: [], dialogs: [] };

    // Remove any old statuses that no longer exist on the API
    // Loop through the banners or dialogs
    const statuses = viewedStatuses[type]?.filter((statusId) => {
      const dialogsOrBanners = obj as Dialog[] & Banner[];
      return dialogsOrBanners.find((status) => statusId === status.id);
    });
    if (!statuses) {
      viewedStatuses[type] = [];
    }
    // Add the id to the array
    viewedStatuses[type]?.push(id as number);
    LocalStorage.set('viewed-status-ids', viewedStatuses);
  }

  async function fetchFeatureFlags() {
    const config = request({
      url: '/feature-flags',
      baseURL: BASE_URL,
      method: 'GET',
      requiresAuth: false,
      instanceId: 'cloud',
      retries: 4,
    });
    config.execute().then((response) => {
      if (response) {
        flags.value = response.data.value;
      }
    });
  }

  function hasFeatureFlag(type: string, system?: System) {
    const hasFlag = flags.value.find((feature) => {
      return feature.type === type;
    });
    // if a system id or hub id has been added to the allow list for a feature flag bypass enabled flag
    const isInAllowList = hasFlag?.['allow-list']?.find((id) => {
      if (id === system?.hub_id || id === system?.id) {
        return id;
      }
    });

    if (isInAllowList) {
      return true;
    }

    if (hasFlag) {
      return hasFlag.enable;
    }
    return true;
  }

  return {
    getArticles,
    getSupportHours,
    getDialogs,
    $reset,
    fetchArticles,
    createTicket,
    fetchSupportHours,
    fetchStatuses,
    saveViewedStatusId,
    fetchFeatureFlags,
    hasFeatureFlag,
  };
});

// Note we  can remove this once the API starts returning Ids
// for now we are creating a unique hash that will be the same
// every time based on the content of the status
function createUniqueIds(data: { banners: Banner[]; dialogs: Dialog[] }) {
  const { banners, dialogs } = data;

  banners.forEach((banner: Banner) => {
    banner.id = hashCode(
      banner.title + banner.description + banner.url + banner.closeable,
    );
  });
  dialogs.forEach((dialogs: Dialog) => {
    dialogs.id = hashCode(dialogs.title + dialogs.description + dialogs.url);
  });
  return { banners, dialogs };
}

// Statuses represent either a banner or a dialog
// We want to filter out any statuses that have been seen or are not targeted to this app.
function filterStatuses(
  statuses: Dialog[] | Banner[],
  type: 'banners' | 'dialogs',
) {
  const statusesToFilter = statuses as Dialog[] & Banner[];
  return statusesToFilter.filter((status) => {
    // If the dialogs have not already been looked at.
    // We save to local storage when a status is looked at.
    const viewedStatuses: StorageStatuses | null =
      LocalStorage.getItem('viewed-status-ids');
    let hasNotSeenStatus = true;
    if (viewedStatuses && viewedStatuses[type])
      hasNotSeenStatus = !viewedStatuses[type]?.find(
        (id: number) => id === status.id,
      );

    // if the user is on one of the targeted platforms returned by the api. the target platforms
    // can be any of the ones listed here https://quasar.dev/options/platform-detection#properties
    let isOnTargetedPlatform = true;
    // If there is a platforms array and the length is greater or equal to 1
    if (status.platforms && status.platforms.length >= 1) {
      const targetedPlatforms = status.platforms as string[];
      isOnTargetedPlatform = targetedPlatforms.some((platform: string) => {
        return Platform.is.hasOwnProperty(platform);
      });
    }

    let isOnTargetVersion = true;
    let isAboveMinVersion = true;
    let isBelowMaxVersion = true;
    if (status.version) {
      // if the app version is  ===  to the target version
      if (status.version.hasOwnProperty('target'))
        isOnTargetVersion = semverCompare(status.version.target, version) === 0;

      // if the app version is greater then the min version
      if (status.version.hasOwnProperty('min')) {
        isAboveMinVersion = semverCompare(status.version.min, version) === -1;
      }
      // if the app version is less then the max version
      if (status.version.hasOwnProperty('max')) {
        isBelowMaxVersion = semverCompare(status.version.max, version) === 1;
      }
    }

    // compute results
    if (
      hasNotSeenStatus &&
      isOnTargetedPlatform &&
      isOnTargetVersion &&
      isAboveMinVersion &&
      isBelowMaxVersion
    ) {
      return status;
    }
  });
}
