// The goal of this composable is to be the API layer of the app - We are replacing the old api import
// TODO: implement cancel tokens that check if the request requires auth and the auth status of the app
// TODO: add caching logic to see if request was recently fetched and if we have data

import {
  StrictUseAxiosReturn,
  UseAxiosReturn,
  useAxios,
} from '@vueuse/integrations/useAxios';
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEnvironments } from 'src/composables/api/useEnvironments';
import { useAuth } from '@luminsmart/building-blocks';
import { useZeroConf } from 'src/composables/useZeroConf';
import { useHubStore } from 'src/stores/useHubStore';
import { handleRequestErrors, notifyResponseErrors, sleep } from 'stores/util';
import { STATUS_MAP, updateSystemStatus } from '../useHealthCheck';
import { captureException } from '@sentry/vue';
import { ref } from 'vue';
import { useStorage } from '@vueuse/core';
import { useLocalAuth } from '../useLocalAuth';
import { useSystemsStore } from 'src/stores/systems';
const MAX_RETRY_ATTEMPTS = 3;
const BASE_RETRY_DELAY_MS = 1000;

const instances = ref<Record<string, AxiosInstance>>({});
interface RequestConfig {
  url: string;
  baseURL?: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  data?: Record<string, unknown> | string; // optional
  params?: Record<string, unknown>; // optional
  instanceId?: string; // optional - if an instance ID is passed in then we will only use that instance
  options?: AxiosRequestConfig;
  headers?: {
    Authorization?: string;
    'X-Local-Token-ID'?: string;
    'X-Local-Token-Key'?: string;
  };
  retries?: number;
  requiresAuth?: boolean;
  // cancelToken?: CancelToken; // TODO: refactor away from cancel token - its deprecated
  fallbackToCloud?: boolean;
  includeInHealthCheck?: boolean;
}

// TODO: Figure out these any's
interface ApiResponse
  extends StrictUseAxiosReturn<any, any, any> /* eslint-disable-line */,
    UseAxiosReturn<any, any, any> {} /* eslint-disable-line */

export function useApi() {
  const { getEnvironment } = useEnvironments();
  const cloudAuth = useAuth();
  const localAuth = useLocalAuth();
  const localAuthTokens = useStorage<
    Record<string, { id: string; secret: string }>
  >('localAuthTokens', {});

  createAxiosInstance('cloud', getEnvironment.value.api.VITE_API_BASE_URL);

  function request(requestConfig: RequestConfig) {
    console.info('[useApi] - request()', requestConfig, localAuthTokens);
    // Setup defaults
    requestConfig.retries = requestConfig.retries || 0;
    requestConfig.requiresAuth =
      requestConfig.requiresAuth !== undefined
        ? requestConfig.requiresAuth
        : true;
    const { url, data, instanceId } = requestConfig;
    let options: AxiosRequestConfig = {};
    if (requestConfig.options) options = requestConfig.options;
    if (requestConfig.baseURL) options.baseURL = requestConfig.baseURL;
    if (requestConfig.params) options.params = requestConfig.params;
    // if (requestConfig.cancelToken)
    //   options.cancelToken = requestConfig.cancelToken;
    if (requestConfig.fallbackToCloud === undefined) {
      requestConfig.fallbackToCloud = true;
    }

    if (requestConfig.includeInHealthCheck === undefined) {
      requestConfig.includeInHealthCheck = true;
    }
    // TODO: we should look into refactoring this chunk of logic, the way we decide if we can go local needs to be expanded on.
    // This is needed for creating a system locally. because our logic for deciding if we can talk locally is derived from if we have a system id this will allow us to post locally to /systems
    // Or if we are trying to connect an lsp to the network and testing if it has come online by pinging the dns info on the hub
    if (
      requestConfig.url.includes('/hubs/') ||
      requestConfig.url.includes('/auth/') ||
      (requestConfig.method === 'POST' &&
        requestConfig.url.endsWith('/systems'))
    ) {
      // see if we have created an instance id for this already
      if (requestConfig.instanceId && requestConfig.baseURL) {
        if (!instances.value[requestConfig.instanceId]) {
          createAxiosInstance(requestConfig.instanceId, requestConfig.baseURL);
        }
      }
    }
    // END Todo

    const { instance, id } = getAxiosInstance(url, instanceId);
    // Setup an abort controller so if our requests go over the timeout of the request it will be canceled
    options.signal = newAbortSignal(
      options.timeout ?? instance.defaults.timeout ?? 5000,
    );

    const config: ApiResponse = useAxios(url, options, instance, {
      immediate: false,
      abortPrevious: false,
    });

    const { isLoading, error } = config;

    // We need to have an optional conf passed in to handle retry logic
    async function execute(): Promise<ApiResponse | void> {
      console.info('[useApi] - execute() - config ', config, cloudAuth);
      try {
        if (requestConfig.requiresAuth) {
          const token = await cloudAuth.getToken();
          requestConfig.headers = {
            Authorization: `Bearer ${token}`,
          };
          // Local Auth - Check if we have a local auth token saved for this instance id (the hub id)
          const localAuthToken = localAuthTokens.value[id];
          if (localAuthToken) {
            requestConfig.headers = {
              ...requestConfig.headers,
              'X-Local-Token-ID': localAuthToken.id,
              'X-Local-Token-Key': localAuthToken.secret,
            };
          }
        }
      } catch (e) {
        console.error('[useApi] - execute() - authorization error', e);
      }
      if (data) requestConfig.data = data;

      try {
        await config.execute(requestConfig);
        // We only want to update the system status indicator when for api calls related to a system
        if (getSystemIdFromUrl(url) && requestConfig.includeInHealthCheck)
          updateSystemStatus('api', STATUS_MAP.ONLINE, {
            config: config,
            instance: id,
            requestConfig,
          });
        return config;
      } catch (e) {
        try {
          // Local Auth - if we get a 401 unauthorized and its one of the auth errors
          if (
            e instanceof AxiosError &&
            e.status === 401 &&
            localAuth.isLocalAuthError(e)
          ) {
            const { getSystemsHubService } = useSystemsStore();
            const service = getSystemsHubService;
            if (service) {
              console.info(
                '[useApi] - Local unauthorized 401 - Attempting to generate local auth token',
                service,
              );
              await localAuth.generateAuthToken(service, instance);
              // Local Auth - Check if we have a local auth token saved for this instance id (the hub id)
              const localAuthToken = localAuthTokens.value[id];
              // Add the token to the request
              if (localAuthToken) {
                requestConfig.headers = {
                  ...requestConfig.headers,
                  'X-Local-Token-ID': localAuthToken.id,
                  'X-Local-Token-Key': localAuthToken.secret,
                };
              }
            }
          }

          await retryRequest(requestConfig, config);
          // We only want to update the system status indicator when for api calls related to a system
          if (getSystemIdFromUrl(url) && requestConfig.includeInHealthCheck)
            updateSystemStatus('api', STATUS_MAP.ONLINE, {
              config: config,
              instance: id,
              requestConfig,
            });
        } catch (retryError) {
          console.error(
            '[retryRequest] - error',
            retryError,
            requestConfig,
            config,
          );
          const error = handleRequestErrors(retryError);
          if (error) notifyResponseErrors(error);
          // We only want to update the system status indicator when for api calls related to a system
          if (getSystemIdFromUrl(url) && requestConfig.includeInHealthCheck)
            updateSystemStatus('api', STATUS_MAP.OFFLINE, {
              config: config,
              instance: id,
              requestConfig,
            });
        }
        if (config.error.value) {
          captureException(config.error.value);
          throw config.error.value;
        } else if (config.data.value) return config;
      }
    }
    function newAbortSignal(timeoutMs: number) {
      const abortController = new AbortController();
      setTimeout(() => abortController.abort(), timeoutMs || 0);

      return abortController.signal;
    }
    return { isLoading, error, execute };
  }

  async function retryRequest(
    requestConfig: RequestConfig,
    config: ApiResponse,
  ) {
    requestConfig.retries = requestConfig.retries || 0;
    if (requestConfig.retries < MAX_RETRY_ATTEMPTS) {
      console.info('[retryRequest]', requestConfig);
      requestConfig.retries++;
      const delay = Math.pow(1.5, requestConfig.retries) * BASE_RETRY_DELAY_MS;
      await sleep(delay);
      try {
        await config.execute(requestConfig);
      } catch (e) {
        await retryRequest(requestConfig, config);
      }
    } else if (
      requestConfig.retries === MAX_RETRY_ATTEMPTS &&
      requestConfig.fallbackToCloud
    ) {
      requestConfig.retries++;
      getEnvironment.value.api.VITE_API_BASE_URL;
      requestConfig.baseURL = getEnvironment.value.api.VITE_API_BASE_URL;
      requestConfig.instanceId = 'cloud';
      await config.execute(requestConfig);
    } else throw '[retryRequest] Failure, Max retries reached';
  }

  return { request, instances };
}

function getAxiosInstance(
  url: string,
  instanceId: string | undefined,
): { instance: AxiosInstance; id: string } {
  if (!instanceId) {
    const systemId = getSystemIdFromUrl(url);
    if (systemId) {
      /**
       * Local Api instance management
       */
      const { getServiceBySystemId } = useZeroConf();
      const service = getServiceBySystemId(systemId);
      if (service) {
        instanceId = service.txtRecord.hub_id;
        if (instanceId) {
          const localApiInstance = instances.value[instanceId];
          if (!localApiInstance) {
            const hubsStore = useHubStore();
            // TODO: Refactor getHubUrl to the useApi
            const { url } = hubsStore.getHubUrl(service);
            return {
              instance: createAxiosInstance(instanceId, url),
              id: instanceId,
            };
          }
          return { instance: localApiInstance, id: instanceId };
        }
      }
    }
    return { instance: instances.value['cloud'], id: 'cloud' };
  }

  const requestedInstance = instances.value[instanceId];
  if (requestedInstance) {
    return { instance: requestedInstance, id: instanceId };
  }

  throw 'useApi: No instance available.';
}

function createAxiosInstance(instanceId: string, baseURL: string) {
  if (!instances.value[instanceId]) {
    const instance = axios.create({
      baseURL,
      timeout: 5000,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    });

    instances.value[instanceId] = instance;
  }

  return instances.value[instanceId];
}

function getSystemIdFromUrl(url: string) {
  const match = url.match(/\/systems\/([^/]+)\/?/);
  if (match) {
    return match[1];
  }
  return null;
}
