import { useZeroConf } from 'src/composables/useZeroConf';

export {
  handleRequestErrors,
  notifyResponseErrors,
} from './handleRequestErrors';
export { sortByNestedKey } from './sortBy';
export { sleep } from './sleep';
export function mergeRecord<
  T extends { id: string; updated_at: string | null },
>(
  /** The state you want to update */
  state: Array<T>,
  /** The actual record we are looking to update in our local state */
  record: T,
  systemId?: string,
): Array<T> {
  const cachedIndex = state.findIndex((e) => e?.id === record?.id);
  const zeroConf = useZeroConf();
  let isLocal = false;
  if (systemId) isLocal = !!zeroConf.getServiceBySystemId(systemId);

  if (cachedIndex !== -1) {
    const cachedValue = state[cachedIndex];
    const apiUpdatedAt = record?.updated_at
      ? Date.parse(record?.updated_at)
      : NaN;
    const cachedUpdatedAt = cachedValue?.updated_at
      ? Date.parse(cachedValue?.updated_at)
      : NaN;

    // If the record is local or the API record is newer than the cached record, update the cached record
    if (
      isLocal ||
      isNaN(apiUpdatedAt) ||
      isNaN(cachedUpdatedAt) ||
      apiUpdatedAt >= cachedUpdatedAt
    ) {
      state[cachedIndex] = record;
    } else {
      console.info(
        '[mergeRecord] using app cache instead of API. App cache is more recent than API response',
        cachedValue,
      );
    }
  } else {
    state.push(record);
  }

  return state;
}

export function removeRecord<T extends { id: string }>(
  state: Array<T>,
  record: T,
) {
  return state.filter((r) => r?.id !== record?.id || !r);
}

export function mergeMultipleRecords<
  T extends { id: string; updated_at: string | null },
>(
  /** The state you want to update */
  state: Array<T>,
  /** The actual records we are looking to update in our local state */
  records: Array<T>,
  systemId?: string,
): Array<T> {
  if (!state && records) return records;
  if (!records && !state) return [];
  // Make sure we don't have any null
  state = state.filter((value) => value);
  let stateMap = new Map(state?.map((record) => [record?.id, record]));
  const zeroConf = useZeroConf();
  let isLocal = false;
  if (systemId) isLocal = !!zeroConf.getServiceBySystemId(systemId);
  // Check the state for records that don't exist in the API response and remove the states records if they don't align
  for (const [id, record] of stateMap) {
    if (records && !records?.find((r) => r.id === id)) {
      state = removeRecord(state, record);
    }
  }
  stateMap = new Map(state.map((record) => [record.id, record]));
  try {
    return records?.map((apiRecord) => {
      const cachedRecord = stateMap.get(apiRecord.id);

      if (!cachedRecord) return apiRecord;

      const apiUpdatedAt = apiRecord.updated_at
        ? Date.parse(apiRecord.updated_at)
        : NaN;
      const cachedUpdatedAt = cachedRecord.updated_at
        ? Date.parse(cachedRecord.updated_at)
        : NaN;

      if (isNaN(apiUpdatedAt) || isNaN(cachedUpdatedAt)) {
        // Use the API record if either `updated_at` value is invalid
        return apiRecord;
      }

      if (isLocal) {
        return apiRecord;
      }
      // Both records have valid `updated_at`; compare timestamps
      return apiUpdatedAt >= cachedUpdatedAt ? apiRecord : cachedRecord;
    });
  } catch (e) {
    console.error('[mergeMultipleRecords] error', e);
    return [];
  }
}

export function hashCode(s: string): number {
  return s.split('').reduce((a: number, b: string) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

/**
 * Compare semantic version strings to see if the current version (a) is greater then the target version (b)
 * @param {string} a - Semantic version 1
 * @param {string} b - Semantic version 2
 * @returns {number} - 1 if a > b
 *                     0 if a = b
 *                    -1 if a < b
 */
export function semverCompare(a: string, b: string) {
  if (a.startsWith(b + '-')) return -1;
  if (b.startsWith(a + '-')) return 1;
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'case',
    caseFirst: 'upper',
  });
}

// Tests for an empty object "{}"
export function isEmptyObject(obj: Record<string, unknown>) {
  return Object.keys(obj).length === 0;
}

export function customRound(num: number) {
  return num % 1 === 0 ? num : Math.round(num * 10) / 10;
}
