import { App } from '@capacitor/app';
import mixpanel, { Config } from 'mixpanel-browser';
import { defineStore } from 'pinia';
import Plausible from 'plausible-tracker';
import { Platform, useQuasar } from 'quasar';
import { useAuth } from 'src/composables/useAuth';
import { useHealthCheck } from 'src/composables/useHealthCheck';
import { computed, watch } from 'vue';
import { version } from '../../package.json';
import { useSystemsStore } from './systems';
import { useStorage } from '@vueuse/core';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';
import { Capacitor } from '@capacitor/core';

export interface AppInfo {
  'package-version': string;
  version: string;
  build: string;
}

// Initialize Plausible (disabled by default)
const plausible = Plausible({
  domain: import.meta.env.VITE_PLAUSIBLE_TRACKING_DOMAIN,
});

// Analytics store
export const useAnalyticsStore = defineStore('AnalyticsStore', () => {
  const q = useQuasar();
  const { user } = useAuth();
  const isDeveloper = useStorage('isDeveloper', false);
  const userId = computed(() => user?.value?.sub);

  // State to track ATT permission
  let canTrackUsers = false;

  // Request ATT Permission for IOS
  async function requestTrackingPermission() {
    if (Capacitor.getPlatform() === 'ios') {
      try {
        const response: AppTrackingStatusResponse =
          await AppTrackingTransparency.requestPermission();
        console.log(`ATT Status: ${response.status}`);
        canTrackUsers = response.status === 'authorized';
      } catch (error) {
        console.error('ATT Error:', error);
        canTrackUsers = false;
      }
    } else {
      // Default to allowing tracking on non-iOS platforms
      canTrackUsers = true;
    }
    return canTrackUsers;
  }

  // Initialize Mixpanel and Plausible
  async function initializeTracking() {
    await requestTrackingPermission();
    if (canTrackUsers) {
      // Setup Mixpanel
      mixpanel.init(import.meta.env.VITE_MIX_PANEL_KEY);
      watch(userId, async () => {
        const platformInfo = Object.keys(Platform.is)
          .filter((key) => Platform.is[key as keyof typeof Platform.is])
          .reduce(
            (obj, key) => {
              obj[key] = Platform.is[key as keyof typeof Platform.is];
              return obj;
            },
            {} as Record<string, string | number | boolean | undefined>,
          );

        const userAgent = q.platform.userAgent;

        if (userId.value) {
          mixpanel.identify(userId.value);
          if (user.value) {
            const appInfo = await getAppInfo();
            mixpanel.people.set({
              name: user.value.name,
              isDeveloper: isDeveloper.value,
              $email: user.value.email,
              appVersion: appInfo.version,
              appBuild: appInfo.build,
              [Capacitor.getPlatform()]: { ...platformInfo, userAgent },
              'package-version': appInfo['package-version'],
            });
          }
        }
      });

      // Enable Plausible
      plausible.enableAutoPageviews();
    } else {
      console.log('Tracking disabled by user choice.');
      mixpanel.opt_out_tracking(); // Stop tracking
    }
  }

  // function removeAuthorizationRefs(jsonBlob: any): any {
  //   const stack: any[] = [jsonBlob]; // Stack for iterative traversal

  //   while (stack.length > 0) {
  //     const current = stack.pop();

  //     if (typeof current === 'object' && current !== null) {
  //       if ('authorization' in current) {
  //         delete current['authorization']; // Remove the key
  //       }

  //       // Push object values or array elements to stack
  //       for (const key in current) {
  //         if (typeof current[key] === 'object' && current[key] !== null) {
  //           stack.push(current[key]);
  //         }
  //       }
  //     }
  //   }

  //   return jsonBlob; // Modified in place, returned for convenience
  // }

  // Track Event
  function trackEvent(eventName: string, data: Record<string, unknown> = {}) {
    if (canTrackUsers) {
      mixpanel.track(eventName, data);
    } else {
      console.info(`Tracking disabled. Skipped event: ${eventName}`);
    }
  }

  // Fetch App Info
  async function getAppInfo() {
    const appInfo: AppInfo = {
      'package-version': '',
      version: '',
      build: '',
    };
    if (q.platform.is.capacitor) {
      const mobileAppInfo = await App.getInfo();
      appInfo.version = mobileAppInfo.version;
      appInfo.build = mobileAppInfo.build;
    }
    if (version) appInfo['package-version'] = version;
    return appInfo;
  }

  // Fetch Metadata
  async function fetchMetadata() {
    const systemsStore = useSystemsStore();
    const { states } = useHealthCheck();
    return {
      email: user?.value?.email,
      userAgent: q.platform.userAgent,
      system: systemsStore.getSelectedSystem,
      appVersion: await getAppInfo(),
      appHealth: states.value,
    };
  }

  function startSessionReply() {
    mixpanel.start_session_recording();
  }

  function stopSessionReply() {
    mixpanel.stop_session_recording();
  }

  // Start analytics tracking

  return {
    $reset() {
      // Not much to reset
    },
    initializeTracking,
    fetchMetadata,
    trackEvent,
    getAppInfo,
    startSessionReply,
    stopSessionReply,
  };
});
