import { defineStore } from 'pinia';
import { Connection } from 'src/types/Connection';
import { Device } from 'src/types/Device';
import {
  FlatAttributes,
  Intent,
  IntentCreateResponse,
  IntentParams,
} from 'src/types/Intent';
import { computed, ref } from 'vue';
import { useSystemsStore } from '../systems';
import { useAnalyticsStore } from '../useAnalyticsStore';
import { useUtilityEventsStore } from '../automations/useUtilityEventsStore';
import { mergeMultipleRecords, mergeRecord, removeRecord } from '../util';
import { useAppBanners } from 'src/composables/useAppBanners';
import { DeviceAttribute } from 'src/types/DeviceAttributes';
import { useAuth } from 'src/composables/useAuth';
import { useApi } from 'src/composables/api/useApi';
import { System } from 'src/types/System';
import { sortBy } from '../util/sortBy';
import { until } from '@vueuse/core';
import { DEVICE_TYPES } from 'src/types/DeviceTypeSchema';

const DEVICE_TIMEOUT = 15000;
const DEVICE_RESUMPTION_DELAY = 'Device Resumption Delay';

export const useDevicesStore = defineStore('devices', () => {
  const auth = useAuth();
  const systemsStore = useSystemsStore();
  const { request } = useApi();

  const devices = ref<Device[]>([]);
  // Devices that aren't a part of system yet, but we can see.
  const connections = ref<Connection[]>([]);
  const active_device_id = ref<string | null>(null);

  function $reset() {
    devices.value = [];
    connections.value = [];
    active_device_id.value = null;
  }

  // Compute the available devices
  const getAvailableDevices = computed(() => {
    const availableDevices = connections.value
      .filter((c) => c.status === 'connected')
      .map((connection) => {
        const connectionsDevices = connection.devices;
        if (connectionsDevices) {
          return connectionsDevices;
        }
        return [];
      });
    const afterFlat = availableDevices
      .flat()
      .filter((device) => !devices.value.find((d) => device.id === d.id));

    const customSort = (a: Device, b: Device) => {
      const aValue = Number(a?.attributes?.line_nums?.value[0]);
      const bValue = Number(b?.attributes?.line_nums?.value[0]);
      return aValue - bValue;
    };
    const afterSort = afterFlat.sort(customSort);
    return afterSort;
  });

  const getDevices = computed(() => {
    return devices?.value?.filter(
      (d) => d.system_id === systemsStore.getSelectedSystemId,
    );
  });

  function getEdgeControllers() {
    return devices.value.filter((device) => device.type === 'edge_circuit');
  }
  function getDeviceById(id: string) {
    const cachedDevice = devices.value.find((device) => device.id === id);
    return cachedDevice;
  }

  async function fetchDevice(id: string) {
    const url = `/systems/${systemsStore.getSelectedSystemId}/devices/${id}`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });
    config.execute().then((response) => {
      if (response) {
        devices.value = mergeRecord(
          devices.value,
          response.data.value,
          systemsStore.getSelectedSystemId,
        );
      }
    });

    return config;
  }

  async function fetchDeviceIntents(id: string) {
    const url = `/systems/${systemsStore.getSelectedSystemId}/devices/${id}/intents`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });
    config.execute().then((response) => {
      if (response) {
        const intents = response.data.value;

        updateDeviceIntentsState(intents, id);
      }
    });

    return config;
  }
  async function fetchDevices(systemId: System['id']) {
    if (!systemId?.length) {
      await until(systemId?.length).toMatch((v) => v > 0, { timeout: 5000 });
      if (!systemId?.length) {
        throw 'no system id selected';
      }
    }
    const url = `/systems/${systemId}/devices`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });
    await config
      .execute()
      .then(async (response) => {
        if (response) {
          devices.value = sortBy(
            mergeMultipleRecords(devices.value, response.data.value, systemId),
            'name',
          );
        }
      })
      .catch((e) => {
        console.error('[useSystemStore() - fetchSystem error', e);
        // see if we get a 404 error from the api only then should we remove the devices
        if (e?.status === 404) {
          devices.value = devices.value.filter(
            (device) => device.system_id !== systemId,
          );
        }
        return null;
      });
    const gvdDevice = getGvd();
    if (gvdDevice) {
      fetchTelemetry(gvdDevice.gvd_id, gvdDevice.system_id);
    }

    return config;
  }

  async function fetchConnections() {
    const appBanners = useAppBanners();
    // We only want to show cached connections for the system we are looking ats
    connections.value = connections.value.filter(
      (connection) => connection.system_id === systemsStore.getSelectedSystemId,
    );
    const url = `/systems/${systemsStore.getSelectedSystemId}/connections`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });
    config.execute().then((response) => {
      if (response) {
        // devices.value = response.data.value;
        connections.value = response.data.value;

        // If the connections are disconnected then lets let the user know
        connections.value?.forEach((connection) => {
          if (connection.status === 'disconnected') {
            appBanners.createBanner({
              id: 'ERR_CONNECTION_DISCONNECTED',
              platforms: [],
              version: null,
              title: `${connection.name} disconnected`,
              description: '',
              classes: 'bg-negative text-white',
              url: null,
              icon: 'error',
              closable: false,
              callout: true,
            });
          } else {
            appBanners.removeBanner('ERR_CONNECTION_DISCONNECTED');
          }
        });
      }
    });

    return config;
  }

  async function registerDevice(
    deviceId: string,
    systemId: string,
  ): Promise<Device | undefined> {
    const url = `/systems/${systemId}/devices/${deviceId}`;
    const config = request({
      url,
      method: 'PUT',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
      data: {
        electrification_mode: false,
      },
    });

    return await config.execute().then((response) => {
      if (response) {
        addDeviceToStore(response.data.value);
        return response?.data.value;
      }
      return;
    });
  }

  async function patchDevice({
    id,
    system_id,
    name,
    // nameplate_rating,
    component_types,
    configurable_attributes,
    electrification_mode = false,
  }: Pick<Device, 'id' | 'system_id' | 'name' | 'electrification_mode'> & {
    // nameplate_rating: number;
    component_types: string[];
    configurable_attributes?: FlatAttributes;
  }): Promise<Device | undefined> {
    const analyticsStore = useAnalyticsStore();
    analyticsStore.trackEvent('update-device', {});
    const url = `/systems/${system_id}/devices/${id}`;
    if (id === 'Uncontrolled Loads') {
      return;
    }

    const config = request({
      url,
      method: 'PATCH',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
      data: {
        name,
        // nameplate_rating,
        component_types,
        configurable_attributes,
        electrification_mode,
      },
    });

    return await config.execute().then((response) => {
      if (response) {
        devices.value = mergeRecord(
          devices.value,
          response.data.value,
          system_id,
        );
        return response?.data.value;
      }
      return;
    });
  }

  async function deleteDevice({
    system_id,
    device_id,
  }: {
    system_id: Device['system_id'];
    device_id: Device['id'];
  }) {
    const analyticsStore = useAnalyticsStore();
    analyticsStore.trackEvent('delete-device', {});
    const url = `/systems/${system_id}/devices/${device_id}`;

    const config = request({
      url,
      method: 'DELETE',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });

    return await config.execute().then((response) => {
      if (response) {
        devices.value = removeRecord(devices.value, response.data.value);
        return response?.data.value;
      }
      return;
    });
  }

  async function deleteIntent({
    device_id,
    intent_id,
  }: {
    device_id: Device['id'];
    intent_id: string;
  }) {
    const systemId = systemsStore.getSelectedSystemId;
    const url = `/systems/${systemId}/devices/${device_id}/intents/${intent_id}`;

    const config = request({
      url,
      method: 'DELETE',
    });

    await config.execute();
    return;
  }

  async function controlDevice({
    device_id,
    intent,
  }: {
    device_id: Device['id'];
    intent: IntentParams;
  }) {
    const analyticsStore = useAnalyticsStore();
    const utilityEventsStore = useUtilityEventsStore();
    const systemId = systemsStore.getSelectedSystemId;
    analyticsStore.trackEvent('control-device', {});
    const url = `/systems/${systemId}/devices/${device_id}/intents`;
    const deviceToUpdate = devices.value.find(
      (device) => device.id === device_id,
    );

    const fleetControlIntent =
      (deviceToUpdate &&
        deviceToUpdate.intents.find((i) =>
          i.actor.name.includes('Fleet Control Event'),
        )) ||
      null;
    if (fleetControlIntent) {
      const utilityEvent =
        utilityEventsStore.getUtilityEventByIntentId(fleetControlIntent);

      if (utilityEvent?.id) {
        await utilityEventsStore.optOutDevice(
          systemId,
          utilityEvent.id,
          device_id,
        );
      }
    }

    const config = request({
      url,
      method: 'POST',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
      data: intent,
    });
    return await config.execute().then((response) => {
      if (response) {
        const data = response.data.value as IntentCreateResponse;
        if (!deviceToUpdate)
          throw Error('Update device not found when updating device store');
        // Workaround for switch_open last_changed value not being returned
        if (
          deviceToUpdate.attributes.switch_open &&
          !deviceToUpdate?.attributes?.switch_open?.last_changed
        ) {
          deviceToUpdate.attributes.switch_open.last_changed =
            new Date().toISOString();
        }

        devices.value = mergeRecord(
          devices.value,
          {
            ...deviceToUpdate,
            updated_at: new Date().toISOString(),
            attributes: Object.assign(
              deviceToUpdate.attributes,
              data.controllable_attributes,
            ),
            intents: data.intents,
          },
          systemId,
        );
      }
    });
  }
  async function turnOnDeviceNoHold(
    device: Device,
    removeResumptionDelayIntent = false,
  ) {
    if (device.intents.length && removeResumptionDelayIntent) {
      for (const intent of device.intents) {
        if (intent?.actor?.name === DEVICE_RESUMPTION_DELAY) {
          await deleteIntent({ device_id: device.id, intent_id: intent.id });
        }
      }
    }

    await controlDevice({
      device_id: device.id,
      intent: {
        actor: await getActor(),
        hold: false,
        controllable_attributes: {
          switch_open: !device.attributes.switch_open?.value,
        },
      },
    });
  }
  async function turnOnDeviceUntilTime(device: Device, expires_at: Date) {
    await controlDevice({
      device_id: device.id,
      intent: {
        actor: await getActor(),
        hold: true,
        expires_at: expires_at.toISOString(),
        controllable_attributes: {
          switch_open: !device.attributes.switch_open?.value,
        },
      },
    });
  }
  async function turnOffDeviceUntilTime(device: Device, expires_at: Date) {
    await controlDevice({
      device_id: device.id,
      intent: {
        actor: await getActor(),
        hold: true,
        expires_at: expires_at.toISOString(),
        controllable_attributes: {
          switch_open: !device.attributes.switch_open?.value,
        },
      },
    });
  }
  async function turnOffDeviceNoHold(device: Device) {
    await controlDevice({
      device_id: device.id,
      intent: {
        actor: await getActor(),
        hold: false,
        controllable_attributes: {
          switch_open: !device.attributes.switch_open?.value,
        },
      },
    });
  }

  // Update the apps local model of a device
  function updateDeviceAttributes(
    attributes: Record<string, DeviceAttribute>,
    deviceId: string,
  ) {
    const device = getDeviceById(deviceId);
    if (!device) {
      console.warn("Device '", deviceId, "' not found");
      return;
    }
    device.attributes = {
      ...device.attributes,
      ...attributes,
    };
    devices.value = mergeRecord(
      devices.value,
      device,
      device.system_id,
    ) as Device[];
  }

  function updateDeviceIntentsState(intents: Intent[], deviceId: string) {
    const device = getDeviceById(deviceId);
    if (!device) {
      console.warn("Device '", deviceId, "' not found");
      return;
    }
    device.intents = intents;
    devices.value = mergeRecord(
      devices.value,
      device,
      device.system_id,
    ) as Device[];
  }

  async function getActor(): Promise<IntentParams['actor']> {
    const { user } = auth;

    return {
      id: user.value?.sub ? user.value.sub : 'no-id',
      name: 'user',
      type: 'user',
    };
  }

  function addDeviceToStore(device: Device) {
    const d = getDeviceById(device.id);

    if (d !== undefined) {
      return;
    }

    devices.value.push(device);
  }

  function removeDeviceFromStore(deviceId: string) {
    const d = getDeviceById(deviceId);

    if (d === undefined) {
      return;
    }

    devices.value = devices.value.filter(
      (device: Device) => device.id !== deviceId,
    );
  }

  function getGvd(): {
    gvd_id: string;
    system_id: string;
    gvd_active: string | number | boolean | string[] | number[];
  } | null {
    const gvdArray = devices.value.filter(
      (device: Device) => device.type === 'gvd',
    );

    if (!gvdArray.length) return null;

    const gvd = gvdArray[0];

    return {
      system_id: gvd.system_id,
      gvd_id: gvd?.id,
      gvd_active: gvd?.attributes?.detected?.value,
    };
  }

  function getMainCts() {
    return devices.value.find((device) => {
      if (device.type === DEVICE_TYPES.LSP_NON_SWITCHABLE_TYPE) {
        const deviceLines: number[] = device.attributes.line_nums?.value ?? [];
        const isMainCt: (13 | 14)[] = deviceLines.filter(
          (linNum) => linNum === 13 || linNum === 14,
        );

        if (isMainCt.length) {
          return device;
        }
      }
    });
  }

  function getAuxCts() {
    return devices.value.find((device) => {
      if (device.type === DEVICE_TYPES.LSP_NON_SWITCHABLE_TYPE) {
        const deviceLines: number[] = device.attributes.line_nums?.value ?? [];
        const isAuxCts: (15 | 16)[] = deviceLines.filter(
          (linNum) => linNum === 15 || linNum === 16,
        );

        if (isAuxCts.length) {
          return device;
        }
      }
    });
  }

  function getControllableDevices() {
    return devices.value.filter(
      (device) =>
        device.type === DEVICE_TYPES['LSP_DEVICE_TYPE'] ||
        device.type === DEVICE_TYPES['EDGE_DEVICE_TYPE'],
    );
  }

  async function fetchTelemetry(deviceId: string, systemId: string) {
    const url = `/systems/${systemId}/devices/${deviceId}/telemetry`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: DEVICE_TIMEOUT,
      },
    });
    config
      .execute()
      .then((response) => {
        if (response) {
          updateDeviceAttributes(response.data.value, deviceId);
        }
      })
      .catch((e) => {
        console.error(e);
      });

    return config;
  }
  return {
    devices,
    connections,
    active_device_id,
    $reset,
    getAvailableDevices,
    getDevices,
    getEdgeControllers,
    getDeviceById,
    fetchDevices,
    fetchDevice,
    fetchConnections,
    registerDevice,
    patchDevice,
    deleteDevice,
    controlDevice,
    turnOffDeviceNoHold,
    turnOffDeviceUntilTime,
    turnOnDeviceNoHold,
    turnOnDeviceUntilTime,
    updateDeviceAttributes,
    addDeviceToStore,
    removeDeviceFromStore,
    getGvd,
    getMainCts,
    getAuxCts,
    getControllableDevices,
    fetchDeviceIntents,
  };
});
