import { ref } from 'vue';
import {
  Barcode,
  BarcodeScanner,
  GoogleBarcodeScannerModuleInstallState,
  BarcodeFormat,
} from '@capacitor-mlkit/barcode-scanning';
import { Capacitor } from '@capacitor/core';

// N.B.(Ben,2023-09-21): With the switch to using the native scan activity this
// is unused and components/elements/BarcodeScanner.vue will never display
const isScanning = ref(false);

// isGoogleReady will be true if the Google barcode scanner module is available
const isGoogleReady = ref(false);

export function useCamera() {
  // init should be called early in the application lifecycle to ensure that
  // the Google barcode scanner module is ready to go when it is needed
  const init = async (): Promise<void> => {
    if (Capacitor.getPlatform() !== 'android') {
      isGoogleReady.value = true;
      return;
    }

    const isGoogleScannerAvailable =
      await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
    if (isGoogleScannerAvailable.available) {
      isGoogleReady.value = true;
      return;
    }

    // The Google barcode scanner module is not available, time to fetch it
    // Start by adding a listener
    BarcodeScanner.addListener(
      'googleBarcodeScannerModuleInstallProgress',
      async (event) => {
        let stateString = 'UNKNOWN';
        switch (event.state) {
          case GoogleBarcodeScannerModuleInstallState.UNKNOWN:
            stateString = 'UNKNOWN';
            break;
          case GoogleBarcodeScannerModuleInstallState.PENDING:
            stateString = 'PENDING';
            break;
          case GoogleBarcodeScannerModuleInstallState.DOWNLOADING:
            stateString = 'DOWNLOADING';
            break;
          case GoogleBarcodeScannerModuleInstallState.CANCELED:
            stateString = 'CANCELED';
            break;
          case GoogleBarcodeScannerModuleInstallState.COMPLETED:
            stateString = 'COMPLETED';
            // Also set that we are ready now
            isGoogleReady.value = true;
            break;
          case GoogleBarcodeScannerModuleInstallState.FAILED:
            stateString = 'FAILED';
            break;
          case GoogleBarcodeScannerModuleInstallState.INSTALLING:
            stateString = 'INSTALLING';
            break;
          case GoogleBarcodeScannerModuleInstallState.DOWNLOAD_PAUSED:
            stateString = 'DOWNLOAD_PAUSED';
            break;
        }
        // LINT (Ben,2023-09-27) Disable no-console to support debugging
        // eslint-disable-next-line no-console
        console.log('googleBarcodeScannerModuleInstallProgress:', stateString);
      },
    );

    // Kick off the installation
    await BarcodeScanner.installGoogleBarcodeScannerModule();
  };

  const stopScan = async () => {
    // Make all elements in the WebView visible again
    document.querySelector('body')?.classList.remove('barcode-scanner-active');

    // Remove all listeners
    await BarcodeScanner.removeAllListeners();

    // Stop the barcode scanner
    await BarcodeScanner.stopScan();
    isScanning.value = false;
  };

  const scan = async (): Promise<Barcode> => {
    isScanning.value = true;
    return new Promise(async (resolve) => {
      document.querySelector('body')?.classList.add('barcode-scanner-active');

      // Remove existing listeners
      await BarcodeScanner.removeAllListeners();

      // Add the stop scan listener
      await BarcodeScanner.addListener('barcodesScanned', async (result) => {
        await stopScan();
        resolve(result.barcodes[0]);
      });

      // Launch the native scanner
      await BarcodeScanner.startScan();
    });
  };

  const scanNative = async (): Promise<Barcode | null> => {
    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.Code128, BarcodeFormat.QrCode],
    });

    if (barcodes.length > 0) {
      return barcodes[0];
    } else {
      return null;
    }
  };

  const checkPermissions = async () => {
    try {
      const { camera } = await BarcodeScanner.checkPermissions();
      return camera;
    } catch (e) {
      return 'denied';
    }
  };

  let hasRequested = false;
  const requestPermissions = async () => {
    if (hasRequested === false) {
      const { camera } = await BarcodeScanner.requestPermissions();
      hasRequested = true;
      return camera;
    } else {
      return await checkPermissions();
    }
  };

  // isReady can be used to check if the camera is ready to use and if not
  // why is it not ready to use, this allows clients to provide user feedback
  // or to request permissions as needed
  const isReady = async (): Promise<IsReadyResult> => {
    const isNative = Capacitor.isNativePlatform();
    if (isNative) {
      const supportedResult = await BarcodeScanner.isSupported();
      if (!supportedResult.supported) {
        return {
          ready: supportedResult.supported,
          reason: 'not-supported',
        };
      }

      const permissionResult = await BarcodeScanner.checkPermissions();
      if (permissionResult.camera !== 'granted') {
        return {
          ready: false,
          reason: 'not-granted',
        };
      }

      if (!isGoogleReady.value) {
        return {
          ready: false,
          reason: 'missing-google',
        };
      }
    }

    return {
      ready: true,
      reason: '',
    };
  };

  const openSettings = async () => {
    return await BarcodeScanner.openSettings();
  };

  return {
    init,
    isReady,
    isScanning,
    scan,
    scanNative,
    stopScan,
    requestPermissions,
    checkPermissions,
    openSettings,
  };
}

export type IsReadyResult = {
  ready: boolean;
  reason: string;
};
