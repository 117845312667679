/* eslint-disable @typescript-eslint/no-explicit-any */

export function sortByNestedKey<T extends Record<string, any>>(
  arr: T[],
  keyPath: string,
): T[] {
  const keys = keyPath.split('.');
  return arr
    .sort((a: T, b: T) => {
      let aValue: any = a,
        bValue: any = b;

      for (const key of keys) {
        if (aValue && typeof aValue === 'object') {
          aValue = aValue[key];
        }

        if (bValue && typeof bValue === 'object') {
          bValue = bValue[key];
        }
      }
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return 0;
    })
    .reverse();
}

export function sortBy(arr: any[], key = 'name') {
  // Copy array so sorce data doesnt mutate
  const arrCopy = [...arr];
  arrCopy.sort(function (a, b) {
    if (a?.[key] < b?.[key]) {
      return -1;
    }
    if (a?.[key] > b?.[key]) {
      return 1;
    }
    return 0;
  });
  return arrCopy;
}

export function sortObjectKeysRecursively(
  obj: Record<string, any>,
): Record<string, any> | null {
  if (!obj || typeof obj !== 'object') return null;

  const sortedObj: Record<string, any> = {};

  const sortedKeys = Object.keys(obj)?.sort();

  for (const key of sortedKeys) {
    const value = obj[key];

    if (value && typeof value === 'object' && !Array.isArray(value)) {
      sortedObj[key] = sortObjectKeysRecursively(value);
    } else {
      sortedObj[key] = value;
    }
  }

  return sortedObj;
}
