import { InstallationState, Step } from 'src/types/Installation';
function generateTemplate() {
  const template: Step[] = [
    {
      route: { name: 'StartSetup' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.start) return true;
        return false;
      },
    },
    {
      route: { name: 'RequiredPermissions' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.permissions) return true;
        return false;
      },
    },
    {
      route: { name: 'ConnectToWiFi' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.connectedToWiFi) return true;
        return false;
      },
    },
    {
      route: { name: 'HardwarePreference' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.hardware) return true;
        return false;
      },
    },

    {
      route: { name: 'ValidateUseCase' },
      isCompleted: function (state: InstallationState) {
        if (
          state?.system ||
          state?.connection ||
          state?.organization === undefined
        )
          return true;
        if (state['overload-control'] || state['other']) {
          return true;
        }
        return false;
      },
    },

    {
      route: { name: 'ConnectionPreference' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.connection) return true;
        if (state.hardware === 'edge') {
          return true;
        }
        return false;
      },
    },
    {
      route: { name: 'ConnectToHub' },
      isCompleted: function (state: InstallationState) {
        // We only need to connect the hub for WiFi
        if (
          state?.system ||
          state?.connection === 'ethernet' ||
          state?.hubConnected
        )
          return true;
        return false;
      },
    },
    {
      route: { name: 'DiscoverHubs' },
      isCompleted: function (state: InstallationState) {
        if (state?.system || state?.createdSystem) return true;
        return false;
      },
    },
    {
      route: { name: 'SetupComplete' },
      isCompleted: function (state: InstallationState) {
        if (state?.system) return true;
        return false;
      },
    },
    {
      route: {
        name: 'ConfigurationChecklist',
        params: {
          system_id: null,
        },
      },
      isCompleted: function (state: InstallationState) {
        if (state?.system) {
          const systemHasName = state?.system?.name?.length > 0;
          let systemHasZipCode = true;
          let systemHasTimezone = true;
          // If we have the full system object (system/id then lets see if it fully configured)
          if (
            state.system.hasOwnProperty('zip_code') &&
            state.system.hasOwnProperty('time_zone')
          ) {
            systemHasZipCode = state?.system?.zip_code.length > 0;
            systemHasTimezone = state?.system?.time_zone.length > 0;
          }
          // Note systems on the cloud are not currently returning this info...

          const systemHasDevices = true;

          let electrificationModeCompleted = true;

          if (state?.electrification_enabled && state?.['overload-control']) {
            let hasControlledDevices = false;
            if (state?.controlledDevices) {
              hasControlledDevices = state?.controlledDevices.length > 0;
            }

            electrificationModeCompleted =
              !!state?.breakerRating &&
              !!state?.amperageLimit &&
              hasControlledDevices;
          }

          return (
            systemHasName &&
            systemHasZipCode &&
            systemHasTimezone &&
            systemHasDevices &&
            electrificationModeCompleted
          );
        } else {
          return false;
        }
      },
    },
  ];

  return template;
}

export default { generateTemplate };
