import { ZeroConfService } from '@luminsmart/capacitor-zeroconf';
import { defineStore } from 'pinia';
import { useApi } from 'src/composables/api/useApi';
import { Provider } from 'src/types/Provider';
import { Connection } from 'src/types/Connection';
import { System } from 'src/types/System';
import { computed, ref } from 'vue';
import { useDevicesStore } from '../devices';
import { mergeRecord, sleep } from '../util';
import { Device } from 'src/types/Device';
import { useAnalyticsStore } from '../useAnalyticsStore';

// https://github.com/luminsmart/bell/blob/main/openapi.yaml
export const useIntegrationsStore = defineStore('integrations', () => {
  const { request } = useApi();
  const devicesStore = useDevicesStore();
  const analyticsStore = useAnalyticsStore();
  const providers = ref<Provider[]>([]);
  const connections = ref<Connection[]>([]);

  const getProviders = computed(() => providers.value);
  const getConnections = computed(() => connections.value);

  function $reset() {
    providers.value = [];
    connections.value = [];
  }

  function getProviderByName(providerName: Provider['name']) {
    return providers.value.find((provider) => providerName === provider.name);
  }

  function getConnectionById(connectionId: Connection['id']) {
    return connections.value?.find(
      (connection) => connection.id === connectionId,
    );
  }

  // TODO: upadte this to system_id/connection-providers
  async function fetchIntegrations(systemId: System['id']) {
    const url = `/systems/${systemId}/connection-providers`;

    const config = request({
      url,
      method: 'GET',
    });

    try {
      const response = await config.execute();
      if (!response?.data) {
        throw Error('No data returned');
      }
      providers.value = response.data.value;
    } catch (error) {
      console.error('Error fetching integrations', error);
    }
  }

  interface BatterConnectionData {
    connection_provider_name: string;
    connection_provider_version: number;
    config: Record<string, string>;
  }
  async function connectBatteryIntegration(
    systemId: System['id'],
    data: BatterConnectionData,
    hub?: ZeroConfService,
  ) {
    if (!hub) {
      throw new Error(
        'You must be on the same WiFi network and locally connected to add your battery.',
      );
    }
    const url = `/systems/${systemId}/connections`;
    const config = request({
      url,
      method: 'POST',
      data: data as unknown as Record<string, unknown>,
      instanceId: hub.txtRecord.hub_id,
    });

    try {
      const response = await config.execute();
      if (!response?.data) {
        throw new Error('No data returned');
      }

      let devices = await waitForDevices(
        response.data.value.devices,
        systemId,
        response.data.value.id,
      );

      if (!devices) {
        devices = await waitForDevices(
          response.data.value.devices,
          systemId,
          response.data.value.id,
        );
      }
      const firstDevice = devices?.[0];
      if (firstDevice) {
        await devicesStore.registerDevice(firstDevice.id, systemId);
        return firstDevice;
      } else {
        throw new Error('No device returned from integration');
      }
    } catch (error) {
      console.error('Error connecting battery to system', error);
      analyticsStore.trackEvent('battery-integration-error', {
        data: error,
        provider: data.connection_provider_name,
      });
      throw new Error('Error connecting battery to system');
    }
  }

  async function waitForDevices(
    devices: Device[],
    systemId: System['id'],
    connectionId: string,
  ) {
    const device = devices?.[0];
    if (!device) {
      await sleep(5000);
      await fetchConnections(systemId);
      const connection = connections.value?.find(
        (connection) => connection.id === connectionId,
      );
      if (connection?.devices) return connection.devices;

      return [];
    }
    return devices;
  }

  async function fetchConnections(systemId: System['id']) {
    const url = `/systems/${systemId}/connections`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: 10000,
      },
    });

    try {
      const response = await config.execute();
      if (!response?.data) {
        throw Error('No data returned');
      }
      connections.value = response.data.value;
    } catch (error) {
      console.error('Error fetching connections', error);
    }
  }

  async function fetchConnection(
    systemId: System['id'],
    connectionId: Connection['id'],
  ) {
    const url = `/systems/${systemId}/connections/${connectionId}`;
    const config = request({
      url,
      method: 'GET',
      options: {
        timeout: 10000,
      },
    });

    try {
      const response = await config.execute();
      if (!response?.data) {
        throw Error('No data returned');
      }
      connections.value = mergeRecord(
        connections.value,
        response.data.value,
        systemId,
      );
    } catch (error) {
      console.error('Error fetching connection', error);
    }
  }

  async function deleteConnection(
    systemId: System['id'],
    connectionId: Connection['id'],
  ) {
    const url = `/systems/${systemId}/connections/${connectionId}`;
    const config = request({
      url,
      method: 'DELETE',
      options: {
        timeout: 10000,
      },
    });

    try {
      const response = await config.execute();
      if (!response?.data) {
        throw Error('No data returned');
      }
      connections.value = connections.value?.filter(
        (connection) => connection.id !== response.data.value.id,
      );
    } catch (error) {
      console.error('Error fetching integrations', error);
    }
  }

  return {
    getProviders,
    getConnections,
    fetchIntegrations,
    fetchConnections,
    fetchConnection,
    getProviderByName,
    getConnectionById,
    connectBatteryIntegration,
    deleteConnection,
    $reset,
  };
});
