{
  "more-options": "More",
  "home": "Home",
  "devices": "Devices",
  "automate": "Automate",
  "insights": "Insights",
  "menu": "Menu",
  "settings": "Settings",
  "get-help": "Get Help",
  "quick-tips": "Quick Tips",
  "privacy-policy": "Privacy Policy",
  "log-out": "Log Out",
  "app-settings": "App",
  "app-settings-header": "App Settings",
  "theme": "Theme",
  "dark-mode": "Dark",
  "light-mode": "Light",
  "system": "System",
  "account-settings": "Account",
  "account-settings-header": "Account Settings",
  "system-settings": "System",
  "system-settings-header": "System Settings",
  "manage-system": "Manage System",
  "manage-devices": "Manage Devices",
  "manage-users": "Manage {orgName} Users",
  "push-notifications": "Push Notifications",
  "delete-account": "Delete Account",
  "delete-account-caption": "Deleting your account is a permanent action that cannot be undone. Once deleted, all your data, including your profile information, app history, and personal settings, will be permanently removed and cannot be recovered.",
  "request-submitted": "Request submitted.",
  "request-submitted-caption": "Your account will be deleted soon.",
  "event-history": "Events History",
  "login": "Login",
  "sign-up": "Create Account",
  "back": "Back",
  "done": "Done",
  "help-and-support": "Support",
  "profile": "Profile",
  "reset-password": "Reset Password",
  "success-reset-password": "Check your email",
  "reset-password-instructions": " Click the reset password button below and we will send instructions to your email on how to reset your password. If you did not receive the email, check your spam filter.",
  "permissions": "Permissions",
  "name": "Name",
  "nickname": "Nickname",
  "email": "Email",
  "phone": "Phone",
  "home-owner": "Home Owner",
  "home-owner-description": "As a homeowner, you can view and manage configured system(s) that you own.",
  "installer": "Installer",
  "installer-description": "As an installer, you can set up and manage systems that you have configured for multiple homeowners.",
  "cancel": "Cancel",
  "about": "About",
  "edit": "Edit",
  "save": "Save",
  "saving": "Saving...",
  "submit": "Submit",
  "continue": "Continue",
  "account-type": "Account Type",
  "clear": "Clear",
  "new": "New",
  "no-new-events": "No New Events",
  "account": "Account",
  "welcome": "Welcome",
  "welcome-lead": "Control what matters",
  "welcome-lead-continue": "The {appName} app helps you manage electrical loads and avoid service upgrades using Panel Guard by {companyName}. If you're here for solar and battery storage, please use the {oldAppName} app instead.",
  "desktop-download-apps": "Download the {appName} app to setup and configure your new {companyName} hardware.",
  "my-systems": "My Systems",
  "configuration": "Configuration",
  "new-installation": "New Installation",
  "get-started": "Get Started",
  "connection-prompt": "Will your system connect via Ethernet or WiFi?",
  "recommend-ethernet": "We strongly recommend using ethernet to ensure faster and more reliable connectivity.",
  "ethernet": "Ethernet",
  "wifi": "WiFi",
  "permissions-required": "Permissions Required",
  "permissions-required-lead": "Some permissions are required to configure your {companyName} System.",
  "location": "Location",
  "location-lead": "Location services are required to setup your system.",
  "location-reason": "Please enable the \"Location\" permission to setup a {companyName} Systems.",
  "location-success": "Location permission granted.",
  "camera": "Camera",
  "camera-reason": "Please enable the \"Camera\" permission to setup a {companyName} Systems.",
  "camera-success": "Camera permission granted.",
  "local-connectivity": "Local Connectivity",
  "local-network-reason": "Please enable the \"Local Network\" permission for local discovery and connectivity to {companyName} Systems.",
  "local-network-success": "Local Network permission granted.",
  "allow-access": "Allow Access",
  "next": "Next",
  "connect-to-wifi": "We need a WiFi connection in order to find your {companyName} system",
  "connect-to-wifi-lead": "Ensure that your phone or tablet is connected to the home WiFi network.",
  "discover-hubs": "Discover",
  "discover-hubs-lead": "Searching for your ",
  "discover-hubs-lead-2": " on the home network.",
  "select-the": " Select the",
  "from-below-to-setup": "you want to setup.",
  "make-sure-hub-is-connected-to-ethernet": "Make sure your hardware is connected to ethernet.",
  "hardware-pref": "What are you setting up?",
  "hardware-pref-lead": "Select the hardware you would like to install.",
  "electrification-worksheet": "Electrification Worksheet",
  "electrification-worksheet-lead": "Installers must fill out the Electrification Worksheet to evaluate its fit for the installation.",
  "electrification-worksheet-text": "Click on the link below to complete this step.",
  "electrification-worksheet-label": "Electrification Worksheet",
  "electrification-worksheet-checkbox-text": "I have completed the Electrification Worksheet",
  "usecases-checklist": "Confirm the use cases this installation will be used for",
  "usecases-checklist-lead": "Choose all that apply",
  "electrification-usecase": "Electrification",
  "grid-services-usecase": "Grid Services",
  "solar-and-storage-usecase": "Solar and Storage",
  "scan-barcode": "Scan Barcode",
  "scan-barcode-lead": "Place the barcode in the camera view.",
  "hub-not-found": "Hub Not Found",
  "hub-not-found-subtitle": "Is your hub in setup mode?",
  "hub-not-found-lead": "Make sure your hub is powered on, connected to your ethernet, and connected to the same WiFi network as your mobile device. Both the on light and the setup light should be on and blinking. ",
  "configuration-checklist-header": "Configuration Checklist",
  "configuration-checklist-lead": "Let's get you configured with {companyName}.",
  "setup-new-system": "Setup a New System",
  "setup-new-system-lead": "Setup a new system",
  "got-it": "Got it!",
  "try-again": "Try Again",
  "i-need-help": "I need help",
  "searching": "Searching...",
  "found": "Found",
  "system-name": "System Name",
  "location-timezone": "Location Timezone",
  "location-zip-code": "Location Zip Code",
  "occupants": "Occupants",
  "successfully-created": "Successfully Created!",
  "successfully-created-lead": "Your system has successfully been created and is now ready to be paired to your devices.",
  "necessary-configuration": "Configuration is necessary in order to use your system. Are you sure you want to exit configuration? All your information will be saved.",
  "necessary-setup": "Without finishing installation, you will not be able to use {companyName}.",
  "are-you-sure": "Are You Sure?",
  "dont-exit": "No, don't exit",
  "yes-exit": "Yes, Exit",
  "configuration-incomplete": "Configuration incomplete",
  "continue-configuration": "Continue configuration",
  "out-of": "out of",
  "steps-completed": "steps completed",
  "system-information-setup-checklist": "System Information",
  "system-information-setup-checklist-lead": "Tell us about your home.",
  "devices-setup-checklist": "Add Devices",
  "devices-setup-checklist-lead": "Configure available devices.",
  "start-new-setup": "Start New Setup",
  "setup-in-progress": "Do you want to continue your previous system configuration?",
  "support-header": "Welcome to Support",
  "support-articles": "Articles",
  "contact-support": "Contact Support",
  "got-questions": "Got Questions?",
  "search-for-help": "Search for help",
  "no-solutions-found": "No solutions found.",
  "subject": "Subject",
  "description": "Description",
  "help-desk-hours": "The helpdesk hours are from 9:00AM to 6:00PM eastern time.",
  "success-with-support-request": "Request submitted, we will be in contact soon!",
  "error-with-support-request": "There was a problem submitting your support request, please try again later.",
  "learn-more": "Learn more",
  "support": "Support",
  "custom-api-endpoint": "Custom API endpoint set",
  "developer-settings": "Developer",
  "api-endpoint-override": "API Endpoint Override",
  "api-endpoint-error": "👆 That URL is invalid and can not be used to override the API endpoint.",
  "invalid-api-error": "The URL provided could not be reached. please verify that the server is running.",
  "app-info": "App Info",
  "version": "Version",
  "build": "Build",
  "online": "Online",
  "offline": "Offline",
  "cloud": "Cloud",
  "local": "Local",
  "other": "Other",
  "live-data-unavailable": "Live data unavailable",
  "reset": "Reset",
  "system-location": "System Location",
  "disconnected-from-home-wifi-header": "WiFi Connection Lost",
  "disconnected-from-home-wifi": "You are no longer connected to your home WiFi network. Please reconnect to ensure proper local app functionality.",
  "close": "close",
  "open-settings": "Open Settings",
  "extend-an-existing-system": "Extend an Existing System",
  "extend-an-existing-system-lead": "When you add to an existing system, everything stays in a unified dashboard.",
  "create-new-system-lead": "If you create a new system, you will create a separate dashboard from your existing one. You will be able to toggle between your systems in the app.",
  "permission": "Permission",
  "auto-connecting": "Auto Connecting...",
  "setup": "Setup",
  "via": "via",
  "hub": "hub",
  "restart-local-network": "Reset local api",
  "skip-setup-pre-checks": "Skip Setup pre checks",
  "publish-service": "Publish service",
  "to": "to",
  "network": "Network",
  "connected-to": "Connected to:",
  "connected-to-hubs-wifi": "Connected to Hub",
  "newest-hardware": "",
  "create-system": "Create system",
  "creating-system": "Creating system...",
  "system-created": "System created",
  "create-system-error": "There was an issue creating your system. please try again.",
  "connected-to-internet": "Connected to internet",
  "do-this-later": "Do this later",
  "configuration-complete": "Complete Configuration",
  "delete": "Delete",
  "turn-on-setup-mode": "Turn on Setup Mode",
  "barcode-scanning": "Barcode Scanning",
  "barcode-scanning-lead": "Place the system barcode inside this area",
  "create-system-setup-checklist": "Create System",
  "utility-information-setup-checklist": "Utility Information",
  "utility-information-setup-checklist-lead": "Have your utility bill ready to complete this step.",
  "user-access-setup-checklist": "User Access",
  "user-access-setup-checklist-lead": "Invite other users to your home.",
  "found-multiple-hubs": "We found a couple of Hubs",
  "choose-a-hub": "Choose the one you want to add this hub to.",
  "extend-or-create": "Extend or Create New System?",
  "extend-or-create-lead": "There is already a configured System on this network. What would you like to do?",
  "extend-system": "Extend System",
  "create-new-system": "Create New System",
  "make-sure-hub-is-in-setup-mode": "Confirm your hub is in setup mode.",
  "make-sure-hub-is-connected-to-eth": "Confirm your hub is connected to ethernet.",
  "add-devices-title": "Add Devices",
  "add-devices-lead": "Here are the devices we've discovered on your network.",
  "configured": "Configured",
  "not-configured": "Select to configure",
  "searching-for-devices": "Searching for devices...",
  "no-devices-found-title": "We can't find any devices on your network.",
  "ensure-pairing": "If you're trying to connect an Edge module, please ensure that it is in pairing mode.",
  "still-having-trouble": "If you continue to experience issues...",
  "support-button": "Contact Support",
  "dont-see": "Don't see your device?",
  "having-trouble": "Having trouble?",
  "lsp_switchable_circuit": "LSP Controllable Circuit",
  "edge_controller": "Edge Controller",
  "lsp_nonswitchable_circuit": "LSP Uncontrollable Circuit",
  "manual-title": "Manual from",
  "utility-control-title": "Utility Control Event",
  "no-intents": "No active controls.",
  "use-dns": "Use DNS",
  "use-dns-lead": " Use the DNS name that the ZeroConf service provides",
  "add-edge-modules-lead": "Find your {companyName} devices by scanning the QR code on the side of each device.",
  "add-edge-modules-btn": "Scan QR Code",
  "add-edge-modules-scanner-not-supported": "Camera is not supported on this phone.",
  "add-edge-modules-scanner-not-granted": "Please grant permissions to this app to use the camera.",
  "add-edge-modules-scanner-missing-google": "Unable to launch scanner on device.",
  "add-edge-modules-invalid-qr-code": "Invalid QR Code",
  "add-edge-modules-failed-to-put": "Network Error",
  "add-edge-modules-put-error": "Unknown Error",
  "add-edge-modules-not-ready": "Pairing not ready",
  "pairing-module": "Pairing module...",
  "zeroconf-port-override": "Override ZeroConf port",
  "failed-to-resolve-local-dns": "Failed to connect to local api",
  "failed-to-resolve-local-dns-lead": "Please restart your phone. if the problem still persists, contact support.",
  "zeroconf-protocol-override": "Override ZeroConf Protocol",
  "show-configured": "Show configured",
  "hide-configured": "Hide configured",
  "loading": "Loading",
  "invite-user": "Invite Users",
  "example-email": "name{'@'}example.com",
  "pending": "pending",
  "remove": "Remove",
  "exit-installation": "Exit Installation",
  "go-home": "Go Home",
  "environments": "Environments",
  "environment": "Environment",
  "manage-users-checklist": "User Access",
  "manage-users-checklist-lead": "Invite other users to your home.",
  "electrification-mode": "Panel Guard",
  "electrification-mode-lead": "Keep current below the home's service limit.",
  "battery-backup-mode-template": "Electrification Mode",
  "battery-backup-mode-template-head": "Use the answer in the ",
  "battery-backup-mode-template-tail": " to fill out the text fields below.",
  "max-current-setting": "Maximum Current Setting",
  "amperage-hint": "Keep electrical current safely under this setpoint",
  "amperage-placeholder": "Enter limit",
  "measured-by": "Measured by",
  "measured-by-lead": "If Main CTs are installed, select \"Main CT\". If no CTs are installed, select \"Controlled Loads\".",
  "controlled-loads": "Controlled Loads",
  "whole-home": "Whole-home (Main CT)",
  "what-is-electrification-mode": "What is Panel Guard?",
  "what-is-electrification-mode-lead": "Panel Guard manages electrical loads in the home so that the electrical demand remains within the capacity of the main service panel.",
  "what-is-electrification-mode-lead-text-2": "This feature allows the addition of new electrical equipment like EV charging stations and heat pumps without triggering a need to upsize the panel and the electrical service from the utility.",
  "what-is-electrification-mode-lead-text-3": "When the Maximum Current Setting (set during the system's commissioning) is reached, loads will be turned off until the overage is avoided. Loads turned off during an overload protection event will be automatically restored after the home load has reduced enough and minimum off times for appliances have been met.",
  "yes": "Yes",
  "no": "No",
  "device-name": "Device name",
  "select-appliance-or-source": "Select an appliance or source",
  "edit-device-select-component-title": "Measures and controls",
  "edit-device-select-component-error": "You must select an appliance!",
  "edit-device-add-another-component": "Add Another",
  "edit-device-phase": "Breaker Row / Phase",
  "edit-device-nameplate-rating": "Electric Nameplate",
  "edit-device-paired": "Tied With",
  "edit-device-select-phase-error": "You must select a phase!",
  "phase": "",
  "controls": "Controls",
  "utility-events": "Utility Events",
  "utility-control-events": "Utility Control Events",
  "utility-control-event": "Utility Control Event",
  "mandatory_fleet_control": "Utility | Mandatory",
  "optional_fleet_control": "Utility | Optional",
  "fleet_control": "Utility",
  "schedule": "Schedule",
  "running": "Running",
  "in-progress": "In Progress",
  "upcoming": "Upcoming",
  "completed": "Completed",
  "disabled": "Disabled",
  "no-automations-text": "There are no {type} events",
  "optional": "Optional",
  "mandatory": "Mandatory",
  "time": "Time",
  "participating-devices": "Participating Devices",
  "mandatory-event-info": "This event is required and may not be edited.",
  "circuit-disabled": "Circuit disabled",
  "circuit-enabled": "Circuit enabled",
  "circuit-disabled-lowercase": "circuit disabled",
  "circuit-enabled-lowercase": "circuit enabled",
  "turned-off": "Turned off",
  "turned-on": "Turned on",
  "turned-off-lowercase": "turned off",
  "turned-on-lowercase": "turned on",
  "opt-out": "Opt Out",
  "opted-out": "Opted Out",
  "opt-out-device": "Opt Out Device",
  "opt-out-device-body": "Participating in Utility Control Events helps protect the grid and can lower your electric bill.",
  "opt-out-device-are-you-sure": "Are you sure you want to opt out {name} from this event? Once you opt out, you cannot re-enroll.",
  "dont-opt-out": "Don't Opt Out",
  "current-consumption": "Current Consumption",
  "top-consumers": "Top Consumers",
  "top-consumers-lead": "These devices are using the most power right now",
  "top-consumer-lead": "This device is using power right now",
  "no-devices-consuming-power": "There are no devices consuming power right now.",
  "view-all-devices": "View all devices",
  "automation_created": "Automation created",
  "fleet_control_event_created": "Utility control event created",
  "no-components-selected": "No components selected.",
  "active-controls": "Active Controls",
  "utility-control-explanation-of-events-title": "What are Utility Control Events?",
  "utility-control-explanation-of-events": "This system is part of a utility-managed fleet, meaning your utility has access to schedule Utility Control Events to turn off enrolled devices during pre-scheduled times determined by your utility.",
  "utility-control-explanation-of-events-2": "There are two types of Utility Control Events: Mandatory and Optional. Your utility decides which events are Mandatory and which are Optional.",
  "utility-control-explanation-of-events-3": "For Optional events, you may edit which devices are enrolled. However, you may not receive the full benefits for participation if you opt out devices from Optional Events.",
  "utility-control-explanation-of-events-4": "If you wish to unenroll from this program, please contact your utility.",
  "on-grid": "Grid up",
  "off-grid": "Down",
  "setup-new-hardware": "Let's get you set up with your new {companyName} system",
  "utility": "Utility",
  "no-events": "There are no events right now.",
  "today": "Today",
  "yesterday": "Yesterday",
  "mins-ago": "mins ago",
  "recent-search": "Recent Searches",
  "event-type": "Event Type",
  "event-time": "Event Time",
  "devices-impacted": "Device(s) Impacted",
  "event-description": "Event Description",
  "at": "at",
  "system-event": "System Event",
  "connection-event": "Connection Event",
  "grid-event": "Grid Event",
  "automation-event": "Automation Event",
  "utility-event": "Utility Event",
  "device-event": "Device Event",
  "manual-control-event": "Manual Control",
  "user-event": "User Event",
  "unknown-event": "Unknown Event Type",
  "date-filter": "Date",
  "automations-filter": "Automations",
  "device_events-filter": "Devices",
  "utility_events-filter": "Utility",
  "grid_events-filter": "Grid",
  "manual_control-filter": "Manual Control",
  "system_events-filter": "System",
  "connection_events-filter": "Connection",
  "user_events-filter": "User",
  "no-filtered-events": "Your filters have returned no results.",
  "clear-all-filters": "Clear all filters",
  "energy-usage": "Energy Usage",
  "no-data": "No data.",
  "total": "Total:",
  "wh": "watt-hours",
  "kwh": "kWh",
  "hour": "Hour",
  "24-hours": "24 Hours",
  "7-days": "7 Days",
  "30-days": "30 Days",
  "90-days": "90 Days",
  "advanced-settings": "Advanced Settings",
  "line-multiplier": "Line {lineId} Multiplier",
  "power-measurement-adjustments": "Power Measurement Adjustments",
  "power-measurement-adjustments-lead": "You can use these values to adjust the power readings for this device, usually setting the value to -1 in order to invert expected negative readings to positive, and vice versa. This is generally only necessary if a line in your LSP was wired backwards or your CTs were installed backwards and should be done so if you have already confirmed that the breaker row/phase is correctly assigned.",
  "power-measurement-adjustments-lead-note": "NOTE: There is no danger to backwards wiring, the only drawback is the wrong sign being attributed to the reading.",
  "nothing-to-see": "Nothing to see here yet...",
  "nothing-to-see-caption": "Optimize your energy use by adding device controls.",
  "create-automation": "Create New",
  "add-new": "Create New",
  "custom-controls": "Custom Controls",
  "create-schedule-caption": "Create a schedule for control of devices.",
  "custom-control-caption": "Programed to run at various times",
  "demand-response-program": "Demand Response Program",
  "ends-at": "Ends at",
  "manage-network": "Manage Network",
  "turnOffDialog": {
    "currentlyControlled": "{deviceName} is currently being controlled...",
    "turnOffQuestion": "How long do you want to turn {deviceName} off for?",
    "control": "{actorName} Control",
    "keepOffUntil": "Keep {switchState} until {expiresAt}",
    "howToProceed": "How would you like to proceed?",
    "turnOffForOneHour": "1 Hour",
    "turnOffFor30Mins": "30 mins",
    "turnOffIndefinitely": "Until I turn it back on"
  },
  "view-mode": "View Mode",
  "supervised-devices": "Monitored Devices",
  "prioritize": "Prioritize",
  "no-devices": "No devices.",
  "choose-what-device-to-keep": "Choose what device to keep on during an overload protection event",
  "prioritized-device-header": "What is a prioritized device?",
  "prioritize-device-text": "A prioritized device is a device that you'd like to run for as long as possible, such that it will not be turned off during an overload protection event unless it's absolutely necessary. Your prioritized device will be the last device to be turned off during an overload protection event.",
  "in-use": "In Use",
  "active": "active",
  "inactive": "inactive",
  "capacity": "Capacity:",
  "amps": "amps",
  "install-for": "Who is this installation for?",
  "myself": "Myself",
  "install-for-organization": "This installation is for {organizationName} and will be added to the {organizationName} system list.",
  "install-for-user": "I am installing this on behalf of myself.",
  "select": "Select",
  "panel-guard": "Panel Guard",
  "step": "Step",
  "of": "of",
  "what-is-main-breaker-rating": "What is the rating of your main panel's breaker?",
  "main-breaker-rating": "Main Breaker Rating",
  "enter-rating": "Enter rating",
  "what-to-control-label": "Controlled Devices",
  "what-to-control-text": "You must control at least enough load so that the load calculation does not exceed the panel rating.",
  "what-to-control-text-2": "It is recommended to choose all 240V loads, even those that do fit within the service size to provide the best user experience",
  "lumin-ct-label": "Are you using {companyName} CTs to monitor all power sources feeding the panel?",
  "lumin-ct-text-0": "All power sources are monitored",
  "lumin-ct-text-1": "Only controlled loads are monitored, CTs are not installed or are not in a compliant configuration",
  "supported-ct-link": "Supported CT configurations",
  "all-sources-monitored": "All power sources are monitored",
  "only-controlled-loads-monitored": "Only controlled loads are monitored, CTs are not installed or are not in a compliant configuration",
  "ct-choose-label": "Choose what device(s) to monitor",
  "ct-choose-subtext": "Add more monitoring devices when there is more than one power source feeding the panel.",
  "ct-main": "Main CT",
  "ct-aux": "Aux CT",
  "what-are-cts-label": "What are CTs",
  "what-are-cts-text-1": "Current Transformers (CTs) are typically installed during the initial setup behind the electrical panel cover in your home. They are clamped around electrical lines to measure power flow.",
  "what-are-cts-text-2": "Main CTs usually measure the power flowing in and out of your home, helping {companyName} calculate and display your overall energy consumption. Additionally, auxiliary CTs are commonly installed to monitor power flow in and out of a solar + storage system.",
  "what-are-cts-text-3": "This combination enables {companyName} to provide a comprehensive view of your energy usage, not only for the devices directly measured and controlled but also for your entire home. This information empowers you to make informed decisions about energy management and efficiency.",
  "pg-current-setting-sticker-reminder": "Reminder to fill out sticker",
  "pg-sticker-reminder-header": "Don't forget to fill out your current limiting sticker",
  "pg-sticker-reminder-body": "Write the Maximum Current Setting on the current limiting sticker included in your {productName} packaging. Make sure to apply it on the side of the panel. All the information needed is in the Review Page.",
  "what-is-max-current-setting": "What is the maximum current setting for this set of devices?",
  "set-max-current-setting": "Set a maximum current setting that fits the following conditions:",
  "fits-the-panel": "Fits within the panel and service size",
  "supports-appliance": "Supports the largest controlled appliance",
  "fits-the-load": "Fits within the load calculation for the rest of the home",
  "enter-max-current-setting": "Enter max current setting",
  "review-and-save": "Review & Save",
  "monitored-by": "Monitored by",
  "amps_caps": "Amps",
  "amperage-limit-hint": "See line 41",
  "measured-by-hint": "See line 42",
  "connecting-hub-to-home-wifi": "Connecting your panel to your local network",
  "connect-hubs-to-homewifi-page": "Give us a second while we connect your panel to your home WiFi network.",
  "select-or-enter": "Wifi Network",
  "wifi-network": "Wifi Network",
  "manually-wifi": "Manually Enter",
  "select-dropdown": "Select Network",
  "refresh-hub-search": "Don't see your device?",
  "No-hubs-were-found": "No hubs were found",
  "view-only": "View Only",
  "view-only-electrification-caption": "Only authorized personnel have permission to edit this page. Questions? Contact your installer or customer support.",
  "view-only-electrification-caption-device-card": "Only authorized personnel have permission to control this device. Questions? Contact your installer or customer support.",
  "no-access-to-view-systems-users": "Organizations can invite users but do not have access to view users.",
  "organizations": "Organizations",
  "added-user-to-system": "Added {email} to system.",
  "network-settings": "Network Settings",
  "reconfigure-wifi": "Reconfigure WiFi",
  "connectToHub": {
    "wifi-setup": "WiFi Setup",
    "wifi-setup-lead": "Make sure the panel is in setup mode and the lights are blinking together.",
    "connecting-to-hotspot": "Connecting to hotspot...",
    "there-were-issues": "Previous error when trying to connect",
    "wrong-password": "Password was incorrect.",
    "connect-to-hotspot": "Connect to hotspot"
  },
  "connectHubToNetwork": {
    "select-your-network": "Select a network",
    "enter-your-network": "Enter network information",
    "add-network": "Add network",
    "name": "Name",
    "password": "Password",
    "connect-lumin-lead": "Next connect {companyName} to your home by providing the home WiFi credentials.",
    "connect": "Connect",
    "connecting": "Connecting Hub..."
  },
  "waitForHubToConnect": {
    "connecting-hub-to-network": "Connecting panel to {network}",
    "waiting-for-hub-lead": "Waiting for the panel to join {network}. This process can take a few minutes",
    "confirm-led-status": "Are the lights on the panel blinking together?",
    "no-error-lets-try-again": "Only one light is blinking / One Light is solid / both lights are solid.",
    "yes-start-over": "The lights are blinking together."
  },
  "add-lsp-device-caption": "LSP controllable circuit -  {volts} V {amps} A max",
  "networkInterface": {
    "label": "Network Interfaces",
    "loading": "Loading...",
    "interface": "Interface:",
    "status": "Status:",
    "connected": "Connected",
    "disconnected": "Disconnected",
    "macAddress": "MAC Address:",
    "ipv4Address": "IPv4 Address:",
    "ipv6Address": "IPv6 Address:"
  },
  "please-give-this-device-a-descriptive-name": "Please give this device a descriptive name.",
  "this-field-is-required": "This field is required",
  "enrolling": "Enrolling",
  "enrolled": "Enrolled",
  "validate-use-case": {
    "title": "Select what this LSP will be used for",
    "caption": "",
    "overload-control": "Overload Control",
    "overload-control-caption": "This home needs {companyName} to control devices to keep current below the home's service limit.",
    "solar-and-storage": "Solar and Storage",
    "solar-and-storage-caption": "{companyName} will be used alongside a home battery to manage loads during an outage.",
    "other": "Other",
    "other-caption": "This system is not being used for Overload Control or Solar and Storage"
  },
  "use-case-issue": {
    "title": "Unsupported Use Case",
    "caption": "The {appName} app doesn't support Solar and Storage installations at this time, but we are working to make that feature available soon. In the meantime, please use the {appNameMorse} app instead. Don't have the {appNameMorse} App installed?"
  },
  "system-not-eligible-for-overload-control": "This system is not eligible for overload control, please contact customer support if you need assistance.",
  "events": {
    "unknown-control": "Unknown control",
    "unknown-device": "Unknown device",
    "system_created": "System created",
    "system_deleted": "System deleted",
    "system_metadata_updated": "{systemName} information changed",
    "system_connection_connected": "{systemName} connected to the internet",
    "system_connection_disconnected": "{systemName} disconnected from the internet",
    "device_added": "{deviceName} added.",
    "device_removed": "{deviceName} removed.",
    "device_configured": "{deviceName} information changed",
    "device_control_executed": "{deviceName} turned {isOff}",
    "automation_created": "{automationName} created",
    "automation_deleted": "{automationName} deleted",
    "automation_metadata_updated": "{automationName} updated",
    "automation_status_changed": "{automationName} {status}",
    "fleet_control_event_created": "Utility control event scheduled",
    "fleet_control_event_canceled": "Utility control event canceled",
    "fleet_control_event_updated": "Utility control event updated",
    "fleet_control_event_opt_out": "Utility control event opted out",
    "alert_status_changed": "{deviceName} - {alertDetails}",
    "active": "active",
    "resolved": "resolved",
    "completed": "completed",
    "scheduled": "scheduled",
    "opted_out": "opted out",
    "in_progress": "in progress",
    "inactive": "inactive"
  },
  "on": "on",
  "off": "off",
  "grid": "Grid",
  "outage": "outage",
  "login-use-case": {
    "title": " Welcome to {companyName}!",
    "body": "The {appName} app's primary feature is Panel Guard. To use {companyName} for Solar & Storage installations, please download the “{oldAppName}” app instead.",
    "acknowledge": "Got it!"
  },
  "off-grid-manager": {
    "title": "Off-Grid Manager",
    "caption": "Runs when the grid is down",
    "flexible-devices-title": "Flexible Devices",
    "flexible-devices-caption": "may be turned off and on during a power outage to keep consumption under {limit}. Lower ranked devices will turn off first.",
    "undesired-devices-title": "Undesired Devices",
    "undesired-devices-caption": "will turn off during a grid outage.",
    "ogm-disabled-caption": "You can only create 1 instance of Off-Grid Manager.",
    "add-to-caption": "Manage what devices are turned off when the grid goes down"
  },
  "insights-page": {
    "title": "Insights",
    "total": "Total {totalLabel}",
    "caption": "View your energy usage and device status.",
    "no-data": "No data available.",
    "no-data-caption": "Optimize your energy use by adding device controls.",
    "by-device": "By Device",
    "whole-home": "Whole-home",
    "all-devices": "All Devices",
    "energy-consumed": "{energyOrPower} {consumedOrProduced}",
    "high-to-low": "High to Low",
    "low-to-high": "Low to High"
  },
  "integrations": {
    "label": "Battery Integration",
    "caption": "Adding a battery to your Lumin system allows us to monitor the State-of-Charge (SoC). As the battery SoC decreases, Lumin can turn off additional loads to extend the battery for your most necessary devices.",
    "footer": "We are working with various storage manufacturers to provide more battery integrations every day.",
    "test-connection": "Test Connection",
    "no-integrations-available": "Integrations are currently unavailable for your system, please come back later or contact support for help.",
    "connection-status": "Connection Status",
    "oauth-flow-dialog-warning": "You are about to be redirected to {providerName}. Once there, please login to your {providerName} account to give Lumin read-only access to your system.",
    "redirect-title": "{providerName} Redirect",
    "connection-unsuccessful": "Connection Unsuccessful",
    "connection-unsuccessful-caption": "We couldn't connect your battery. Please verify that the information is entered correctly and try again. If the problem persists, reach out to Customer Support.",
    "contact-support": "Contact Support ➜",
    "try-again": "Try Again",
    "connection-successful": "Battery Successfully Connected!",
    "connection-successful-caption": "Now that you've added a battery integration to your system, you can prioritize devices based on available stored energy!",
    "connection-successful-caption-2": "All set! you have added your battery to your system, You can now create smart controls using your batter!",
    "prioritize-devices": "Prioritize Devices",
    "okay": "Okay",
    "mobile-only-title": "Battery integrations can only be configured using our mobile apps.",
    "mobile-only-caption": "Download the {appName} app to connect your battery to your system.",
    "available-integrations": "Available Integrations",
    "create-ogm": "Setup Off-Grid Manager"
  },
  "local-network-permission-disabled": "Local access unavailable, please allow the 'local network' permission.",
  "connections": {
    "label": "Connections",
    "caption": "Add services to {companyName}",
    "linked-integrations": "Linked connections",
    "available-integrations": "Available connections",
    "available-device": "{devicesCount} devices available",
    "battery-integration": "Battery Integration",
    "battery-integration-caption": "Configure and add new battery integrations.",
    "confirm-deletion": "Remove {providerName} from system?",
    "confirm-deletion-caption": "Are you sure you want to delete this connection?",
    "manage-connections": "Manage Connections"
  },
  "dont-save": "Don't save",
  "unsaved-changes-label": "You have unsaved changes!",
  "unsaved-changes-caption": "Do you want to save the changes you made?",
  "connectionDetails": {
    "caption": "manage your devices",
    "configured": "Configured",
    "add-devices": "Pair New Devices",
    "available-devices": "Available Devices",
    "no-devices-found": "No devices found",
    "searching-for-devices": "Searching for devices...",
    "camera-permission-required": "Please enable the \"Camera\" permission to pair your devices"
  }
}
