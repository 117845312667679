<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { Notify, useQuasar } from 'quasar';
import { Auth0VueClient } from '@auth0/auth0-vue';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import BarcodeScanner from 'components/elements/BarcodeScanner';
import FullPageLoading from 'components/elements/FullPageLoading.vue';
import LErrorBoundary from 'components/elements/LErrorBoundary.vue';
import { setStatusBar, setupTheme } from 'components/theme';
import { useAppLoadingStatus } from 'composables/useAppLoadingStatus';
import { useAuth } from 'composables/useAuth';
import { useBrowser } from 'composables/useBrowser';
import { usePushNotifications } from 'composables/usePushNotifications';
import { useAnalyticsStore } from 'stores/useAnalyticsStore';
import { useLiveUpdates } from './composables/useLiveUpdates';
import { useZeroConf } from './composables/useZeroConf';
import { useAppBanners } from './composables/useAppBanners';
import { useI18n } from 'vue-i18n';
import { Capacitor } from '@capacitor/core';

// TODO: Move code to homeowner layout that only should run for the home owner user type.
const auth: Auth0VueClient = useAuth();
const {
  clientOptions,
  error,
  handleRedirectCallback,
  isAuthenticated,
  logout,
} = auth;
const analyticsStore = useAnalyticsStore();
const router = useRouter();
const { isAppLoading, setLoading } = useAppLoadingStatus();
const q = useQuasar();
const { openUrl } = useBrowser();
const pushNotifications = usePushNotifications();
const zeroConf = useZeroConf();
const appBanners = useAppBanners();
const { t } = useI18n();

onMounted(async () => {
  if (q.platform.is.capacitor) {
    await useLiveUpdates();
    zeroConf.broadcastAppService();

    if (
      Capacitor.getPlatform() === 'ios' &&
      zeroConf.checkPermissions.value === 'denied'
    ) {
      appBanners.createBanner({
        id: 'IOS_LOCAL_NETWORK_PERMISSION_DISABLED',
        platforms: [],
        version: null,
        title: t('local-network-permission-disabled'),
        description: '',
        classes: 'text-negative',
        url: '',
        closable: true,
        callout: false,
      });
    } else {
      appBanners.removeBanner('IOS_LOCAL_NETWORK_PERMISSION_DISABLED');
    }
  }

  // Start up any app tracking and monitoring
  analyticsStore.initializeTracking();
});

if (q.platform.is.capacitor) {
  // Setup Capacitor to handel browser redirects back from auth0
  // TODO: we probably want to move this somewhere else
  App.addListener('appUrlOpen', async function (data: URLOpenListenerEvent) {
    setLoading(true);
    let { url } = data;
    // Authentication call back
    if (
      url.includes('state=') &&
      (url.includes('error=') || url.includes('code='))
    ) {
      try {
        await handleRedirectCallback(url);
      } catch (e) {
        console.error(e);
      }
      if (q.platform.is.ios) {
        Browser.close();
      }
      router.push({ name: 'Root' });

      // Wait to setup push notifications until user has authenticated
      if (isAuthenticated.value === true) {
        pushNotifications.handleNotifications();
      }
    } else if (url.includes(clientOptions?.authorizationParams?.redirect_uri)) {
      // Logout callback check for auth0 callback url
      // Note: thinking about this check is this to general of check?
      try {
        const query = url.split('?').slice(1);
        if (query.length) await handleRedirectCallback(url);
      } catch (e) {
        console.error(e);
      }
      if (q.platform.is.ios) {
        Browser.close();
      }
      router.push({ name: 'Root' });
    }
    setLoading(false);
  });
  // End of Capacitor auth setup
}

// Watch for errors and log the user out if we get an error
watch(error, async () => {
  if (!error.value) return;
  const errors = ['invalid_grant', 'missing_refresh_token'];
  // analyticsStore.trackEvent('auth0-error-watcher', {
  //   auth0error: error,
  // });
  if (
    error.value &&
    errors.includes(error.value.error) &&
    isAuthenticated.value
  ) {
    await logout({
      logoutParams: {
        returnTo: clientOptions.authorizationParams.redirect_uri,
      },
      openUrl: (url: string) => openUrl(url, '_self'),
    });
    router.push({ name: 'Root' });
  } else {
    console.error('App - watch - Auth0Error', error.value?.error);
  }
});

// Setup the theme
setupTheme();
setStatusBar();

Notify.setDefaults({
  group: true,
  position: 'top',
  timeout: 1000,
  actions: [
    {
      icon: 'close',
      color: 'white',
      round: true,
    },
  ],
});
</script>

<template>
  <LErrorBoundary>
    <router-view />
    <!-- We want the bar code scanner to be at the top of the dom -->
    <BarcodeScanner v-if="q.platform.is.capacitor" />
    <FullPageLoading v-show="isAppLoading" />
  </LErrorBoundary>
</template>
